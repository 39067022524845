<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">SMS 리스트</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div>
          <div class="form-inp sm">
            <v-select outlined hide-details placeholder="선택하세요">
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="auto" content-class="calendar-modal" color="#FFF">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dateRangeText" v-bind="attrs" v-on="on" readonly outlined hide-details
                class="form-inp icon-calendar ml-2" append-icon="svg-calendar" label="일자"></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker v-model="dates[0]" no-title dark color="#F7AFAE" locale="ko-KR" :first-day-of-week="1"
                :day-format="mixin_getDate" @change="startDate"></v-date-picker>
              <v-date-picker v-model="dates[1]" dark color="#F7AFAE" no-title show-current="false" locale="ko-KR"
                :first-day-of-week="1" :day-format="mixin_getDate" :min="dates[0]" @change="endDate"></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn outlined class="btn-default" @click="menu1 = false">확인</v-btn>
            </div>
          </v-menu>
          <div class="form-inp sm ml-2">
            <v-select :items="SEL_CATEGORY" item-text="title" outlined hide-details placeholder="선택하세요" label="사용구분">
              <template v-slot:label>
                발송구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-text-field class="form-inp lg ml-2" name="" label="전화번호" outlined hide-details></v-text-field>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn outlined class="btn-etc2"> 조회 </v-btn>
        </div>
      </div>
    </div>
    <!-- SMS 목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">SMS 목록
        <div class="ml-auto">
          <v-btn outlined class="btn-point ml-2">엑셀다운로드</v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <v-data-table dense fixed-header height="549px" :headers="gridDataHeaders" :items="gridDataText"
          :items-per-page="30" item-key="index" :page.sync="page" hide-default-footer class="grid-default" show-select
          @page-count="pageCount = $event" id="mainTable" v-model="checkedList" @dblclick:row="dblClickData"
          :item-class="isActiveRow" @click:row="rowSelect">
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="totalVisible" next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"></v-pagination>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { mixin } from "@/mixin/mixin.js";
import DialogVueDropzone from "@/components/VueDropzone.vue";
import api from "@/store/apiUtil.js";

export default {
  mixins: [mixin],
  name: "MENU_M110801", //name은 'MENU_' + 파일명 조합

  components: {

  },
  data() {
    return {
      dialogVueDropzone: false,
      menu1: false,
      menu2: false,
      treeItems: [],
      SEL_CATEGORY: [
        { title: "전체", value: "" },
        { title: "수신", value: "1" },
        { title: "발신", value: "2" },
      ],
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      detailDates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      gridDataHeaders: [
        { text: "No", align: "center", value: "", width: "70px", sortable: true, },
        { text: "발송구분", value: "", align: "left", sortable: true },
        { text: "일자", value: "", align: "left", sortable: true },
        { text: "발신번호", value: "", align: "left", sortable: true },
        { text: "수신번호", value: "", align: "left", sortable: true },
        { text: "SMS 명", value: "", align: "left", sortable: true },
        { text: "SMS 내용", value: "", align: "center", sortable: true },
      ],
      gridDataText: [],

      textareaRules: [v => v.length <= 80 || '80자 제한'],
      textareaValue: '80자 제한!',

    }

  },
  methods: {
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    startDetailDate(e) {
      this.detailDates[0] = e;
    },
    endDetailDate(e) {
      this.detailDates[1] = e;
    },
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      console.log('저장');
      this[`dialog${type}`] = false;
    },
    unfoldTreeview() {
      this.$refs.treeview1.updateAll(true);
    },
    foldTreeview() {
      this.$refs.treeview1.updateAll(false);
    },
    showAlert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    detailDateRangeText() {
      var newStartDate = this.detailDates[0];
      var newEndDate = this.detailDates[1];
      this.detailDateRange = newStartDate + " ~ " + newEndDate;
    },
  }
};

</script>